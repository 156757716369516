.content {

    &__header {
        font-size: $xLargeFontSize;
        font-weight: 400;
        text-align: center;

        @include md {
            font-size: $bigFontSize;
        }
    }

    &__body {
        font-size: $normalFontSize;

        @include md {
            font-size: $mediumFontSize;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__container {
        padding-bottom: $defaultPaddingY;
        padding-left: $defaultPaddingX;
        padding-right: $defaultPaddingX;
        padding-top: $defaultPaddingY;

        @include md {
            padding-bottom: $mediumPaddingY;
            padding-top: $mediumPaddingY;
        }

        &--small-padding-y {
            padding-bottom: $smallPaddingY;
            padding-top: $smallPaddingY;

            @include md {
                padding-bottom: $defaultPaddingY;
                padding-top: $defaultPaddingY;
            }
        }

        &--fixed-padding-left {
            @include lg {
                @include containerPaddingLeft($screenSize: 960px);
            }

            @include xl {
                @include containerPaddingLeft($screenSize: 1140px);
            }
        }

        &--fixed-padding-right {
            @include lg {
                @include containerPaddingRight($screenSize: 960px);
            }

            @include xl {
                @include containerPaddingRight($screenSize: 1140px);
            }
        }
    }
}

$backColor: #ffffff;
$headerBackColor: #ffffff;
$menuBackColor: #ffffff;
$footerBackColor: #222222;

$emBackColor1: #378c31;
$lightBackColor1: #ffffff;
$lightBackColor2: #adadad;
$lightBackColor3: #ECF0F1;
$darkBackColor1: #434343;

$defaultTextColor: #333333;
$emTextColor1: #378c31;
$lightTextColor1: #ffffff;
$lightTextColor2: #adadad;
$darkTextColor1: #434343;

$defaultButtonTextColor: #ffffff;
$defaultButtonBackColor: #adadad;
$defaultButtonEmTextColor: #ffffff;
$defaultButtonEmBackColor: #000000;


.accordion-pane {
    padding-bottom: $defaultPaddingY;
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;
    padding-top: $defaultPaddingY;

    &__header {
        text-align: center;

        @include md {
            text-align: left;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__icon {
            width: 100%;
            padding-bottom: $smallPaddingY;

            @include md {
                padding: $defaultPaddingX;
                display: inline-block;
                width: 16.6%;
                padding-left: $defaultPaddingX;
            }

            @include lg {
                width: 25%;
            }

            @include xl {
                width: 16.6%;
            }

            img {
                width: 20%;

                @include md {
                    width: 75%;
                }
            }
        }

        p {
            width: 100%;

            @include md {
                display: inline-block;
                width: 83.4%;
                padding-left: $defaultPaddingX;
                padding-right: $defaultPaddingX;
            }

            @include lg {
                width: 75%;
            }

            @include xl {
                width: 83.4%;
            }

        }
    }



    &__body {
        @include xl {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__carousel {
            width: 100%;
            padding-left: $defaultPaddingX;
            padding-right: $defaultPaddingX;


            @include xl {
                display: inline-block;
                width: 66.4%;
                padding-top: $defaultPaddingY;
                padding-left: $defaultPaddingX;
            }

            img {
                padding-top: $defaultPaddingY;
                padding-bottom: $defaultPaddingY;
            }

            .owl-prev,
            .owl-next {
                 width: 15px;
                 position: absolute;
                 top: 17%;
                 display: block !important;
                 border: 0px solid black;

                 @include md {
                     top: 27%;
                 }

                 @include lg {
                     top: 20%;
                 }

                 @include xl {
                     top: 17%;
                 }

                 span {
                     font-size: 50px;
                     color: $emTextColor1;
                 }
            }

            .owl-prev {
                margin-left: -30px !important;
            }

            .owl-next {
                 right: -35px;
            }

            .owl-theme .owl-nav [class*="owl-"]:hover {
                background: none;
            }

            .accordion-carousel {
                margin-left: $mediumMarginY;
            }

            &-selected {
                width:95%;
                margin-left: auto;
                margin-right: auto;

                @include md {
                    width:75%;
                }

                @include lg {
                    width:95%;
                }
            }
        }

        &__focus {
            display: inline-block;
            width: 100%;

            @include xl {
                width: 33.6%;
                padding-left: $defaultPaddingX;
            }

            .clear-row {
                clear: both;

                @include sm {
                    clear:none;
                }

                @include xl {
                    clear:both;
                }
            }


            &__item {
                width: 50%;
                padding-left: $defaultPaddingX;
                padding-right: $defaultPaddingX;
                float: left;
                text-align: center;
                text-transform: uppercase;

                @include sm {
                    width: 25%;
                }

                @include xl {
                    width: 50%;
                }

                img {
                    width: 60%;
                    padding-top: $defaultPaddingY;
                    padding-bottom: $smallPaddingY;

                    @include md {
                        padding-top: 0;
                    }

                    @include xl {
                        padding-top: $defaultPaddingY;
                    }
                }
            }
        }
    }
}

.opacity-filter-container {
    position: relative;
    z-index: 0;
}

div.opacity-filter,
div.opacity-filter--hover {
    height: 100%;
    left:0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include defaultTransition();
}

div.opacity-filter--black-30 { background-color: rgba(0,0,0,.3); }

div.opacity-filter--em1-60 { background-color: rgba(44,140,49,.6); }

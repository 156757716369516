

.accordion {
    background-color: $lightBackColor1;

    @include lg {
        display: flex;
        height: 1050px;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    @include xl {
        height: 850px;
    }

    // Custom breakpoints to adjust height
    @media (min-width: 1680px) {
        height: 950px;
    }

    @media (min-width: 1920px) {
        height: 1050px;
    }

    @media (min-width: 2560px) {
        height: 1300px;
    }

    &__toggler {
      cursor: pointer;

      &-desktop {
        display: inline-block;

        @include lg {
          display: none;
        }
      }
    }

    &__item {


        @include lg {
            flex: 1;
            display: flex;
            align-items: stretch;
            height: auto;

            @include transition(flex .3s)
        }

        &__tab {
            position: relative;

            @include imageBackgroundCover($height: 200px);

            @include lg {
                flex: 1;
                height: 100%;
            }

            &--sport {
                background-image: url('/img/sport.jpg')
            }

            &--professional {
                background-image: url('/img/professional.jpg')
            }

            &--individual {
                background-image: url('/img/individual.jpg')
            }

            &__opacity-filter {
                background-color: rgba(67,67,67,.6);
            }
        }

        &__title {
            color: $lightTextColor1;
            font-size: $largeFontSize;
            font-weight: 400;
            margin-bottom: 0;
            margin-left: 50px;
            line-height: 1.1;
            padding-bottom: $defaultPaddingY;
            padding-left: $defaultPaddingX;
            padding-right: $defaultPaddingX;
            padding-top: $defaultPaddingY;

            @include md {
                font-size: $xLargeFontSize;
                margin-left: 80px;
            }

            @include lg {
                text-indent: -9999px //Hide it
            }
        }

        // Using an SVG image to manage the vetical title as positionning/size seems inconsistent on flex resize
        // Trying to inline svg as an alternative
        &__title-image {
            display: none;

            // Inline svg text style
            text {
                fill: $lightTextColor1;
                font-size: $xLargeFontSize;
                glyph-orientation-vertical: 90;
                line-height: 1;
                stroke: $lightTextColor1;
                text-anchor: start;
                writing-mode: tb;

                @include lg {
                }

                @include xl {
                }

            }

            @include lg {
                bottom: 150px;
                display: block;
                right: 37px;
                position: absolute;
            }

            &--double {
                right: 12px;
            }
        }

        &__icon {
            left: 15px;
            position: absolute;
            top: 25px;
            width: 40px;

            @include md {
                top: 30px;
                width: 60px;
            }

            @include lg {
                bottom: 30px;
                left: auto;
                right: 30px;
                top: auto;
                width: 70px;
            }
        }

        &__toggler {
            color: $lightTextColor1 !important; //TMP
            cursor: pointer;
            position: absolute;
            right: 15px;
            text-decoration: none !important;
            top: 10px;
            width: 30px;

            @include defaultTransition();

            @include md {
                width: 40px;
            }

            @include lg {
                left: auto;
                right: 35px;
                top: 15px;
                width: 60px;
            }
        }

        &__content {
            height: auto;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            background-color: $lightBackColor3;

            @include transition(max-height .3s, opacity .8s);

            @include lg {
                flex: 0;

                @include transition(flex .3s, opacity .8s);
            }

        }

        .accordion-pane {
            display: none;
        }
    }

    &__item--active {

        @include lg {
            flex: 5;
        }

        .accordion__item__toggler {
            transform: rotate(-45deg);

            @include defaultTransition();
        }

        .accordion__item__tab__opacity-filter {
            background-color: rgba(44,140,49,.6);
        }

        .accordion__item__content {
            max-height: 1600px; // THIS value must be greater thant the maximum height
            opacity: 1;

            @include transition(max-height .3s, opacity .8s);

            @include lg {
                flex: 4;
                max-height: none;

                @include transition(flex .3s, opacity .8s);
            }
        }

        .accordion-pane {
            display: block;
        }
    }
}

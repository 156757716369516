.business {

    &__title {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        background-color: $lightBackColor1;
    }

    &__image {
        background-image: url('/img/business.jpg');
        flex: 1;
        min-height: 150px;

        @include imageBackgroundCover($height: 150px);

        @include md {
            height: 250px;
            min-height: 250px;
        }

        @include lg {
            flex: 2;
        }

    }

    &__description {
        background-color: $lightBackColor2;
        color: $lightTextColor1;
        flex: 1;
    }

    &__domains {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-bottom: $defaultPaddingY;
        padding-top: $defaultPaddingY;
        background-color: $lightBackColor1;

        @include md {
            flex-direction: row;
        }

        @include lg {
            flex: 2;
        }

        &__item {
            margin-bottom: $defaultMarginY;
            padding-left: $defaultPaddingX;
            padding-right: $defaultPaddingX;
            text-align: center;
            width: 100%;

            @include md {
                margin-bottom: 0;
                width: 33.33333%;
            }

            &__title {
                font-weight: 400;
                font-size: $mediumFontSize;
                text-align: center;
                text-transform: uppercase;
            }

            &__icon {
                margin-bottom: $defaultMarginY;
                margin-top: $defaultMarginY;
                width: 30%;

                @include sm {
                    width: 20%;
                }

                @include md {
                    width: 30%;
                }

                @include lg {
                    width: 40%;
                }
            }
        }
    }
}

$defaultFontFamily: 'Dosis', sans-serif;

$defaultFontSize: 16px;
$em: $defaultFontSize * 1em;

$normalFontSize: 1em;
$smallFontSize: 0.875em;      //14px
$mediumFontSize: 1.1875em;    //19px
$largeFontSize: 1.4375em;     //23px
$xLargeFontSize: 2.9375em;    //47px
$bigFontSize: 4.125em;        //66px

.form {

    &__row {
        display: flex;
        flex-direction: column;

        @include md {
            flex-direction: row;
        }
    }

    &__group {
        flex: 1;
        margin-bottom: $defaultMarginY;

        @include md {
            margin-right: $defaultMarginX;

            &:last-child{
                margin-right: 0;
            }
        }
    }

    &__control {
        background-color: $lightBackColor1;
        color: $darkTextColor1;
        border: none;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        width: 100%;

        &::placeholder {
            color: $lightTextColor2;
            text-transform: uppercase;
        }
    }

    &__buttons {
        padding-top: $defaultPaddingY / 2;
        text-align: center;

        @include md {
            padding-top: 0;
            text-align: right;
        }
    }

    &__button {
        @include defaultButton();
        @include buttonColor();
        padding-left: 60px;
        padding-right: 60px;
    }

    .rgpd {

        &__section {
            font-size: $smallFontSize;

            @include md {
                display: flex;
            }
        }

        &__body{
            @include md {
                flex: 1;
                padding-right: 15px;
            }
        }

        &__checkbox {
            padding-top: $defaultPaddingY / 2;

            @include md {
                flex: 1;
                padding-left: 15px;
                padding-top: 0;
            }
        }
    }

    #private-policy {
        height: 25px;
        width: 25px;
        vertical-align: top;
    }

}

.flex-container {
    display: flex;
}

.flex-direction-row {
    flex-direction: row;

    &--lg {
        @include lg {
            flex-direction: row !important;
        }
    }
}

.flex-direction-row-reverse {
    flex-direction: row-reverse;

    &--lg {
        @include lg {
            flex-direction: row-reverse !important;
        }
    }
}

.flex-direction-column {
    flex-direction: column;

    &--lg {
        @include lg {
            flex-direction: column !important;
        }
    }
}

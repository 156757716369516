.home-hero {

    @include lg {
        height: 475px;
    }

    &__video {
      width: 100%;
      z-index: -1;

      @include lg {
          position: absolute;
          right: 0;
          top: 55px;
      }

    }

    &__empty {
        flex: 2;
        position:relative;
    }

    &__intro {
        padding-top: $mediumPaddingX;
        padding-bottom: $mediumPaddingX;
        position: absolute;
        width:100%;
        background-color: $emBackColor1;
        color: $lightTextColor1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &__title {
            font-size: $xLargeFontSize;
        }

        &__body {
            padding-top: $defaultPaddingX;
            font-weight: 200;
            font-size: $largeFontSize;
        }


        @include lg {
            flex: 1;
            position: relative;
            background-color: rgba(55, 140, 49, 0.6);
            /*background-color: rgba(11, 123, 1, 0.8);*/
        }

    }

}

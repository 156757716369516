.navbar {
    background-color: $lightBackColor1;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.4);
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 1999;

    &__header {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 140px;
        justify-content: space-between;
        padding-left: $defaultPaddingX;
        padding-right: $defaultPaddingX;

        &--small {
            height: 80px;

            .navbar__header__logo {
                width: 180px;
            }
        }

        &__logo {
            width: 240px;
        }

        &__menu {
            display: none;

            @include lg {
                display: block;
            }

            &__item {
                display: inline-block;
                margin-left: $defaultMarginX;
                margin-right: $defaultMarginX;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-size: $mediumFontSize;
                    text-transform: uppercase;
                    cursor: pointer;

                    @include transition(border .2s);

                    &:hover {
                        border-bottom: 2px solid $emTextColor1;
                        text-decoration: none;
                    }
                }
            }
        }

        &__menu-open {
            display: block;

            @include lg {
                display: none;
            }
        }
    }

}

.menu-button {
    @include menuButton();

    &__icon {
        height: 32px;
    }
}

.mobile-menu {
    background-color: $menuBackColor;
    height: 100vh;
    left: 100%;
    overflow: scroll;
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @include defaultTransition();

    &--active {
        left: 0;
    }

    &__menu-close {
        margin-top: 21px;
        text-align: right;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        height: calc(100% - 58px);
        justify-content: center;

        &__items {
            @include withBorderBottomCenter($borderWidth: 1px, $width: 40%, $marginBottom: 60px, $paddingBottom: 60px);
        }

        &__item {
            margin-bottom: $defaultMarginY;
            margin-left: $defaultMarginX;
            margin-right: $defaultMarginX;
            margin-top: $defaultMarginY;
            text-align: center;

            @include md {
                display: inline-block;
            }

            a {
                color: $defaultTextColor;
                font-size: $largeFontSize;
                text-transform: uppercase;
                cursor: pointer;

                @include transition(border .2s);

                &:hover {
                    border-bottom: 2px solid $emTextColor1;
                    text-decoration: none;
                }
            }
        }
    }

}

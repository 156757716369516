.team {

    &__description {
        flex: 1;

        @include lg {
            flex: 2;
        }
    }

    &__staff {
        align-items: center;
        background-color: $lightBackColor2;
        color: $lightTextColor1;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding-bottom: $defaultPaddingY;
        padding-top: $defaultPaddingY;

        &--primary {
            background-color: $emBackColor1;
        }

        &__name {
            font-size: $mediumFontSize;
            font-weight: 400;
            text-transform: uppercase;

            @include withBorderBottomCenter($borderColor: $lightTextColor1, $marginBottom: 40px, $paddingBottom: 20px);

            @include md {
                font-size: $largeFontSize;
            }
        }

        &__avatar {
            border-radius: 50%;
            width: 30%;
        }
    }

    &__image {
        background-image: url('/img/team.jpg');
        flex: 1;
        min-height: 150px;

        @include imageBackgroundCover($height: 150px);

        @include md {
            height: 250px;
            min-height: 250px;
        }

        @include lg {
            flex: 2;
        }

    }
}

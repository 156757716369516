@mixin imageBackgroundCover($width: 100%, $height: 100%) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: $height;
    width: $width;
    -webkit-backface-visibility: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@mixin containerPaddingLeft($screenSize) {
    padding-left: calc(((100vw - #{$screenSize}) / 2) + 15px);
}

@mixin containerPaddingRight($screenSize) {
    padding-right: calc(((100vw - #{$screenSize}) / 2) + 15px);
}

/* Headers borders utils */
@mixin withBorderBottom ($borderColor: $emTextColor1, $borderWidth: 3px, $width: 40px, $marginBottom: 30px, $paddingBottom: 30px) {
    // Reset
    margin: 0;
    padding: 0;

    margin-bottom: $marginBottom;
    padding-bottom: $paddingBottom;
    position:relative;

    &:after {
        // Reset
        border: none;

        border-bottom: $borderWidth solid $borderColor;
        content: " ";
        position: absolute;
        bottom: 0;
        width: $width;
    }
}

@mixin withBorderBottomCenter($borderColor: $emTextColor1, $borderWidth: 3px, $width: 40px, $marginBottom: 30px, $paddingBottom: 30px) {
    @include withBorderBottom($borderColor: $borderColor, $borderWidth: $borderWidth, $width: $width, $marginBottom: $marginBottom, $paddingBottom: $paddingBottom);
    text-align: center;

    &:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@mixin menuButton($fontSize: $largeFontSize, $marginLeft: 0, $marginRight: 0) {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: $fontSize;
    margin-left: $marginLeft;
    margin-right: $marginRight;
    padding-left: 0;
    padding-right: 0;

    &:focus { outline:0; }
}


@mixin defaultButton($fontSize: $defaultFontSize, $marginLeft: 0, $marginRight: 0, $marginY: 5px) {
    border: none;
    cursor: pointer;
    display: inline-block;
    font-family: $defaultFontFamily;
    font-size: $fontSize;
    font-weight: 600;
    line-height: 1;
    margin-bottom: $marginY;
    margin-left: $marginLeft;
    margin-right: $marginRight;
    margin-top: $marginY;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;

    @include defaultTransition();
}

@mixin buttonColor($textColor: $defaultButtonTextColor, $background: $defaultButtonBackColor, $emTextColor: $defaultButtonEmTextColor, $emBackground: $defaultButtonEmBackColor) {
    background-color: $background;
    color: $textColor !important;

    &:hover,
    &:focus {
        background-color: $emBackground;
        color: $emTextColor !important;
    }

    &:visited { color: $textColor; }
}

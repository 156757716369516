/*! custom.css - Project style entry point */

/* ==========================================================================
   Author's custom styles - Follow BEM and Components CSS file used for helpers
   ========================================================================== */

html,
body {
   height: 100%;
   padding: 0;
   margin: 0;
   width: 100%;
}

body {
   background-color: $lightBackColor1;
   color: $defaultTextColor;
   font-family: $defaultFontFamily;
   font-size: $defaultFontSize;
   padding-top: 140px;

   &.header--small {
       padding-top: 80px;
   }
}

img,
video {
   width: 100%;
}

a,
a:hover,
a:visited,
a:focus { color: inherit; }

ul {
   padding: 0;
   list-style: none;
}

*:focus {
   outline: none;
}

.grecaptcha-badge {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}

/* Media query imports - Follows BS notation*/
@include sm {

}

@include md {

}

@include lg {

}

@include xl {

}

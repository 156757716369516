.contact {
    background-color: $darkBackColor1;
    color: $lightTextColor1;
    padding-bottom: $defaultPaddingY;
    padding-top: $defaultPaddingY;

    @include md {
        padding-bottom: $mediumPaddingY;
        padding-top: $mediumPaddingY;
    }

    &__title {
        align-items: center;
    }

    &__address {
        flex: 1;
        text-transform: uppercase;

        &__title {
            font-weight: 400;
        }

        &__address,
        &__contact {
            margin-bottom: 0;
        }

        a {
            text-transform: none;
        }
    }

    &__form {
        flex: 1;
        padding-bottom: $smallPaddingY;
        padding-left: $defaultPaddingX;
        padding-right: $defaultPaddingX;
        padding-top: $smallPaddingY;

        @include md {
            padding-bottom: $defaultPaddingY;
            padding-top: $defaultPaddingY;
        }

        @include lg {
            flex: 2;
        }

    }
}

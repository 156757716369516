.footer {
    align-items: center;
    background-color: $emBackColor1;
    color: $lightTextColor1;
    display: flex;
    flex-direction: column;
    padding-bottom: $defaultPaddingY;
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;
    padding-top: $defaultPaddingY;

    @include md {
        padding-bottom: $mediumPaddingY;
        padding-top: $mediumPaddingY;
    }

    @include lg {
        flex-direction: row;
    }

    &__logo {
        flex: 1;
        margin-bottom: $defaultMarginY;

        @include lg {
            margin-bottom: 0;
        }

        &__img {
            width: 150px;

            @include lg {
                width: 200px;
            }
        }
    }

    &__menu {
        flex: 1;
        text-align: center;

        @include lg {
            flex: 2;
        }

        &__item {
            margin-bottom: 5px;
            margin-top: 5px;
            text-align: center;

            @include lg {
                display: inline-block;
                margin-bottom: 0;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 0;
                text-align: left;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                font-size: $defaultFontSize;
                text-transform: uppercase;
                cursor: pointer;

                @include defaultTransition();
            }
        }
    }

    &__copyright {
        flex: 1;

        &__br {
            display: none;

            @include lg {
                display: inline;
            }
            @include xl {
                display: none;
            }
        }
    }

}
